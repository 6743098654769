<template>
  <b-card>
    <h4 class="mb-0">
      Edit Kegiatan
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                <b-form-group label="Tahun Akademik" label-for="academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="academic_year_id" v-model="dataStudent.academic_year_id"
                    :reduce="(academic_year_id) => academic_year_id.id" placeholder="Tahun Akademik"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="academicYear" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            <b-form-group label="Tanggal Mulai" label-for="start_date">
              <validation-provider #default="{ errors }" name="start_date" rules="required">
                <b-form-input id="start_date" v-model="dataStudent.start_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Mulai" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tanggal Selesai" label-for="end_date">
              <validation-provider #default="{ errors }" name="end_date" rules="required">
                <b-form-input id="end_date" v-model="dataStudent.end_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Selesai" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tipe" label-for="type">
              <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-input id="type" v-model="dataStudent.type" :state="errors.length > 0 ? false : null"
                  placeholder="Tipe" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Penyelenggara" label-for="organizer">
              <validation-provider #default="{ errors }" name="organizer" rules="required">
                <b-form-input id="organizer" v-model="dataStudent.organizer" :state="errors.length > 0 ? false : null"
                  placeholder="Penyelenggara" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Status" label-for="status">
              <validation-provider #default="{ errors }" name="status" rules="required">
                <b-form-input id="status" v-model="dataStudent.status" :state="errors.length > 0 ? false : null"
                  placeholder="status" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>            
          </b-col>                                     
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({   
    required,
    name: '',
    academicYear : [],
    dataStudent: {
      academic_year_id: '',
      semester: '',
      start_date: '',
      end_date: '',
      type: '',
      organizer: '',
      status: '',
      employee_name: '',      
    },    
  }),
  computed: {    
  },
  created() {
    this.getStudentDetail()
    this.getAcademicYears()
  },
  methods: {
    async getAcademicYears() {
      try {
        const response = await this.$http.get('/academicyears')
        this.academicYear = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Academic Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/schoolactivities/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.academic_year_id = data.academic_year_id
      this.dataStudent.semester = data.semester      
      this.dataStudent.start_date = data.start_date.substr(0, 10)      
      this.dataStudent.end_date = data.end_date.substr(0, 10)  
      this.dataStudent.type = data.type      
      this.dataStudent.organizer = data.organizer         
      this.dataStudent.status = data.status         
      this.dataStudent.employee_name = data.employee_name
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            Object.keys(this.locations).forEach(key => {
              dataFormStudent.append(key, this.locations[key])
            })
            this.$http.put(`/students/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            // alertnya()
          } catch (err) {
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
